
import { defineComponent, reactive, computed } from 'vue'
export default defineComponent({
  props: ['propDate'],
  setup(props, { emit }) {
    const date = computed({
      get() {
        return props.propDate
      },
      set(val: any) {
        emit('update', val)
      }
    })

    const masks = reactive({
      input: 'YYYY-MM-DD'
    })

    const popover = reactive({
      visibility: 'click'
    })

    function preDate() {
      emit('preDate')
    }

    function nextDate() {
      emit('nextDate')
    }

    return {
      date,
      masks,
      popover,
      preDate,
      nextDate
    }
  }
})
