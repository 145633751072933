
import { defineComponent, ref, reactive, computed, toRef, inject } from 'vue'

import { useStore } from 'vuex'
import DatePicker from '@/views/components/datePicker.vue'
import moment from 'moment'
import usePagination from '@/plugins/usePagination'
import { device, employee, modalInstance, notifyInstance } from '@/type'
import axios from '@/http'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

interface clockRecord {
  employeeNo: string
  clockNo?: string
  clockIn: string
  clockOut: string
  workingSeconds: number
}

export default defineComponent({
  components: {
    DatePicker
  },
  props: ['propEmployees', 'originDate'],
  setup(props, { emit }) {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const employees = computed({
      get() {
        return props.propEmployees
      },
      set(val: any) {
        emit('update:propEmployees', val)
      }
    })

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)
    const originDate = computed(() => props.originDate)
    const exportType = ref('Clock')
    const devices = ref(new Array<device>())

    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 20,
      lastPage: 1,
      total: 0,
      clockRecords: ref(new Array<clockRecord>())
    })

    const date =
      originDate.value === undefined || originDate.value === null || originDate.value === ''
        ? ref(moment().startOf('day').format('YYYY-MM-DD'))
        : ref(moment(originDate.value).startOf('day').format('YYYY-MM-DD'))

    const paginationSetting = reactive({
      page: toRef(paginationData, 'page'),
      pageSize: toRef(paginationData, 'pageSize'),
      api: `/clocks/${storeId.value}`,
      payload: {
        date: date
      }
    })

    const initDevice = axios
      .get(`/devices/${storeId.value}`)
      .then((res) => {
        const data = res.data
        devices.value = data.devices
      })
      .catch((err) => {
        notify(
          {
            classType: 'error',
            message: `資訊載入失敗 ${err.response.errors.detail}`,
            closeAble: false
          },
          3000
        )
      })

    function init() {
      const initPagination = usePagination(paginationSetting).then((res) => {
        pagination = res
      })

      Promise.all([initDevice, initPagination]).then(function () {
        getData()
      })
    }

    init()

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.clockRecords = data.items
    }

    function getEmployeeName(employeeNo: string) {
      const employee = employees.value.find((employee: employee) => {
        return employee.no === employeeNo
      })
      if (employee !== undefined) {
        return employee.name
      }
      return ''
    }

    function formatDateTime(time: any) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }

    function updateDate(updateDate: any) {
      date.value = moment(updateDate).format('YYYY-MM-DD')
      goPage(1)
    }

    function nextDate() {
      date.value = moment(date.value).add(1, 'day').format('YYYY-MM-DD')
    }

    function preDate() {
      date.value = moment(date.value).subtract(1, 'day').format('YYYY-MM-DD')
    }

    const monthStart = computed(() =>
      moment(date.value).startOf('month').format('YYYY-MM-DD')
    )
    const monthEnd = computed(() =>
      moment(date.value).endOf('month').format('YYYY-MM-DD')
    )

    function getDeviceName(deviceId: number) {
      const device = devices.value.find((device: device) => {
        return parseInt(device.id) === deviceId
      })
      if (device !== undefined) {
        return device.name
      }
      return ''
    }

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            date: date.value
          },
          modal,
          notify,
          router
        )
      }
    }

    return {
      pagination,
      paginationData,
      date,
      updateDate,
      init,
      nextPage,
      prePage,
      goPage,
      getEmployeeName,
      formatDateTime,
      nextDate,
      preDate,
      monthStart,
      monthEnd,
      exportExcelClick,
      getDeviceName
    }
  }
})
