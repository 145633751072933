import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, {
    key: _ctx.$route.path,
    "prop-employees": _ctx.employees,
    "onUpdate:prop-employees": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.employees) = $event))
  }, null, 8, ["prop-employees"]))
}