
import { computed, defineComponent, inject, ref } from 'vue'
import axios from '@/http'
import { useStore } from 'vuex'
import { employee, notifyInstance } from '@/type'

export default defineComponent({
  setup() {
    const notify = inject('notify') as notifyInstance
    const store = useStore()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const employees = ref(new Array<employee>())

    function initEmployee() {
      axios
        .get(`/employees/${storeId.value}`)
        .then((res) => {
          const data = res.data
          employees.value = data.employees
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    initEmployee()

    return {
      employees
    }
  }
})
