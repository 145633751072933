
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRef,
  onUpdated,
  inject,
  watch
} from 'vue'
import axios from '@/http'
import { useRoute, useRouter } from 'vue-router'
import DateRangePicker from '@/views/components/dateRangePicker.vue'
import usePagination from '@/plugins/usePagination'
import moment from 'moment'
import { useStore } from 'vuex'
import { employee, modalInstance, notifyInstance } from '@/type'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

interface clockRecord {
  employeeNo: string
  clockNo?: string
  clockIn: string
  clockOut: string
  workingSeconds: number
}

export default defineComponent({
  components: {
    DateRangePicker
  },
  props: ['propEmployees', 'rangeStart', 'rangeEnd', 'employeeNo', 'date'],
  setup(props, { emit }) {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const route = useRoute()

    const employees = computed({
      get() {
        return props.propEmployees
      },
      set(val: any) {
        emit('update:propEmployees', val)
      }
    })

    const exportType = ref('ClockPersonal')

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)
    const router = useRouter()
    const employeeNo = computed(() => props.employeeNo)
    const originDate = computed(() => props.date)
    const rangeStart = computed(
      () => props.rangeStart ?? moment().startOf('month')
    )
    const rangeEnd = computed(() => props.rangeEnd ?? moment().endOf('month'))

    const status = ref(true)

    const employee = computed(() => {
      return employees.value.find((employee: employee) => {
        return employee.no === employeeNo.value
      })
    })

    watch(
      employee,
      (newValue) => {
        if (newValue !== undefined) {
          status.value = Boolean(newValue.status)
        }
      },
      {
        deep: true,
        immediate: true
      }
    )

    const filterEmployee = computed(() => {
      return employees.value.filter((employee: employee) => {
        return Boolean(employee.status) === status.value
      })
    })

    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 10,
      lastPage: 1,
      total: 0,
      clockRecords: ref(new Array<clockRecord>())
    })

    const statistics = reactive({
      totalClockInTimes: 0,
      totalClockOutTimes: 0,
      totalWorkingSeconds: 0
    })

    const range = reactive({
      start: moment(rangeStart.value).startOf('day').format('YYYY-MM-DDTHH:mm'),
      end: moment(rangeEnd.value).endOf('day').format('YYYY-MM-DDTHH:mm')
    })

    const paginationSetting = reactive({
      page: toRef(paginationData, 'page'),
      pageSize: toRef(paginationData, 'pageSize'),
      api: `/clocks/${storeId.value}/${employeeNo.value}`,
      payload: {
        startTime: toRef(range, 'start'),
        endTime: toRef(range, 'end')
      }
    })

    function fetchStatistics() {
      axios
        .get(`/clocks/${storeId.value}/${employeeNo.value}/statistics`, {
          params: {
            startTime: range.start,
            endTime: range.end
          }
        })
        .then((res) => {
          const data = res.data
          statistics.totalClockInTimes = data.totalClockInTimes
          statistics.totalClockOutTimes = data.totalClockOutTimes
          statistics.totalWorkingSeconds = data.totalWorkingSeconds
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function init() {
      const initPagination = usePagination(paginationSetting).then((res) => {
        pagination = res
      })
      initPagination.then(() => {
        fetchStatistics()
        getData()
      })
    }

    init()

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.clockRecords = data.items
    }

    function updateRange(updateRange: any) {
      range.start = moment(updateRange.start).format('YYYY-MM-DDTHH:mm')
      range.end = moment(updateRange.end).format('YYYY-MM-DDTHH:mm')
      fetchStatistics()
      goPage(1)
    }

    function changeEmployee(employeeNo: string) {
      router.push({
        name: 'clockPersonal',
        params: {
          employeeNo: employeeNo,
          rangeStart: range.start,
          rangeEnd: range.end
        }
      })
    }

    function formatTime(seconds: number) {
      if (seconds === null) {
        return ''
      }
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
    }

    function padTo2Digits(num: number) {
      return num.toString().padStart(2, '0')
    }

    function timeDiff(endTime: any, startTime: any) {
      return moment(endTime).diff(moment(startTime)) / 1000
    }

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            startTime: range.start,
            endTime: range.end,
            employeeNo: employeeNo.value
          },
          modal,
          notify,
          router
        )
      }
    }

    onUpdated(() => {
      if (employee.value === undefined) {
        router.push({
          name: 'clockRecords'
        })
      }
    })

    return {
      range,
      employees,
      updateRange,
      goPage,
      prePage,
      nextPage,
      paginationData,
      changeEmployee,
      employee,
      formatTime,
      statistics,
      status,
      filterEmployee,
      timeDiff,
      exportExcelClick,
      originDate
    }
  }
})
